import React, { useState, useEffect } from "react";
import "./home.css";
import bgPhoto from "../../123.jpg";

function Home() {
  const [poems, setPoems] = useState([]);
  const [randomPoems, setRandomPoems] = useState([]);

  useEffect(() => {
    // Initial list of poems
    const poemList = [
      {
        id: 1,
        poem: `This is poem number 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
      {
        id: 2,
        poem: `This is poem number 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
      {
        id: 3,
        poem: `This is poem number 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
      {
        id: 4,
        poem: `This is poem number 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
      {
        id: 5,
        poem: `This is poem number 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
      {
        id: 6,
        poem: `This is poem number 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
    ];

    setPoems(poemList);

    // Pick 3 random poems
    const getRandomPoems = () => {
      const shuffled = [...poemList].sort(() => Math.random() - 0.5);
      return shuffled.slice(0, 6);
    };

    setRandomPoems(getRandomPoems());
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={bgPhoto} style={{ maxWidth: "60%" }} />
      </div>
      <div className="poem-grid">
        {randomPoems.map((poem) => (
          <div key={poem.id} className="poem-card">
            <h3>Poem {poem.id}</h3>
            <p>{poem.poem}</p>
          </div>
        ))}
      </div>
    </>
  );
}

export default Home;
