import React, { useState } from "react";
import "./App.css";
import "./NavBar.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import SocialFooter from "./components/SocialFooter";
import PeopleIveMet from "./pages/people/PeopleIveMet";
import Places from "./pages/places/Places";
import Voices from "./pages/voices/Voices";
import Faces from "./pages/faces/Faces";
import LightbulbMoments from "./pages/littlelightbulbmoments/LightBulbMoments.jsx";
import ColourMeGreen from "./pages/colourmegreen/ColourMeGreen";
import Home from "./pages/home/Home";
import Modal from "./Modal";
import profilePhoto from "./rkb_photo.jpg";

const App = () => {
  const [isModalopen, setModalOpen] = useState(false);
  return (
    <>
    <Router>
      <div className="container">
        <nav className="navbar">
          <div className="navbar-header" onClick={() => setModalOpen(true)}>
            <a style={{ marginBottom: "10px", border:"1px #8000ff solid", padding: "4px", backgroundColor: "#c5c5ff" }}>
              {/* <Link className="link" to={"/"}> */}
                Ranee Kaur Banerjee
              {/* </Link> */}
            </a>
          </div>
          <div style={{ width: "100%" }}>
            <ul className="navbar-menu">
            <li style={{ backgroundColor: "#ddheed" }}>
                <Link className="navbar-item" to={"/"}>
                  HOME
                </Link>
              </li>
              <li style={{ backgroundColor: "#ddheed" }}>
                <Link className="navbar-item" to={"/people"}>
                  PEOPLE
                </Link>
              </li>
              <li>
                <Link className="navbar-item" to={"/places"}>
                  PLACES
                </Link>
              </li>
              <li>
                <Link className="navbar-item" to={"/voices"}>
                  VOICES
                </Link>
              </li>
              <li>
                <Link className="navbar-item" to={"/faces"}>
                  FACES
                </Link>
              </li>
              <li>
                <Link className="navbar-item" to={"/lightbulb-moments"}>
                  LITTLE <br />
                  <a style={{ background: "yellow" }}>LIGHTBULB</a> <br />{" "}
                  MOMENTS
                </Link>
              </li>
              <li>
                <Link className="navbar-item" to={"/colour-me-green"}>
                  COLOUR ME GREEN
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {isModalopen && (
        <Modal onClose={() => setModalOpen(false)}>
          <div style={{ display: "flex", flexDirection: "row" , justifyContent:"space-between"}}>
            <div>
              <p>Dr Ranee Kaur Banerjee</p>
              <p>ABC</p>
            </div>
            <div>
              <img style={{"height":"250px", marginRight:"10px"}} src={profilePhoto} />
            </div>
          </div>
        </Modal>
      )}

      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/people" element={<PeopleIveMet />} />
        <Route path="/places" element={<Places />} />
        <Route path="/voices" element={<Voices />} />
        <Route path="/faces" element={<Faces />} />
        <Route path="/lightbulb-moments" element={<LightbulbMoments />} />
        <Route path="/colour-me-green" element={<ColourMeGreen />} />
      </Routes>
    </Router>
    <SocialFooter />
    </>
  );
};

export default App;
